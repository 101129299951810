import {Component} from "react";
import ApiFetcher from "../wrappers/ApiFetcher";
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
import {Col, Row} from "react-bootstrap";

export default class Graph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        const endpoint = 'daycounter'
        const api_version = '0.2'
        const param = "n"
        const arg = 30
        ApiFetcher(endpoint, 'GET', api_version, param, arg).then((res) => {
            if (res) {
                res.json().then(data => {
                    this.setState({
                        data: data.reverse()
                    })
                })
            }
        })
    }

    render() {
        return(
            <Row>
                <Col sm={12} lg={12} md={12} xl={12} xs={12} xxl={12}>
                    <ResponsiveContainer width="100%" height={500}>
                        <LineChart margin={{ top: 20, right: 30, left: 0, bottom: 0 }} data={this.state.data}>
                            <Line type="monotone" dataKey="count" stroke="#8884d8" />
                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
        )
    }
}