import {Component} from "react";
import ApiFetcher from "../wrappers/ApiFetcher";
import {Col, Row, Table} from "react-bootstrap";

export default class Top extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        const endpoint = 'top'
        const api_version = '0.2'
        ApiFetcher(endpoint, 'GET', api_version).then((res) => {
            if (res) {
                res.json().then(data => {
                    this.setState({
                        data: data
                    })
                })
            }
        })
    }

    render() {
        let rows = this.state.data.map((item, key) => {
            return(
                <tr key={key}>
                    {Object.keys(item).map((i, k)=> <td key={k}>{item[i]}</td>)}
                </tr>
            )
        })
        return(
            <Row>
                <Col sm={"auto"} lg={"auto"} md={"auto"} xl={"auto"} xs={"auto"} xxl={"auto"}>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <td>Дата</td>
                                <td>Кол-во сообщений</td>
                            </tr>
                            {rows}
                        </thead>
                    </Table>
                </Col>
            </Row>
        )
    }
}