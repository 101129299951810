import {Component} from "react";
import Header from "./Header";
import {Alert, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {YMaps, Map, Placemark, Clusterer} from "react-yandex-maps";
import ApiFetcher from "../wrappers/ApiFetcher";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import AboutProject from "./AboutProject";
import ScrollUpButton from "react-scroll-up-button";
import AboutAPI from "./AboutAPI";
import Footer from "./Footer";
import Weather from "./Weather";
import Top from "./Top";
import Graph from "./Graph";

export default class MainMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            date: ""
          };
          this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
        [name]: value
        });
      }

    componentDidMount() {
        const endpoint = 'locations'
        const api_version = '0.2'
        const param = 'date'
        let arg = (new Date().getFullYear()) + "-" + (new Date().getMonth() +1) + "-" + (new Date().getDate())
        ApiFetcher(endpoint, 'GET', api_version, param, arg).then((res) => {
            if (res) {
                res.json().then(data => {
                    this.setState({
                        locations: data
                    })
                })
            } else {
                console.log('Nothing')
            }
        })
    }

    clickHandler = (value) => {
        const endpoint = 'locations'
        const api_version = '0.2'
        const param = 'date'
        let arg = (value.getFullYear() + "-" + (value.getMonth() +1) + "-" + value.getDate())
        ApiFetcher(endpoint, 'GET', api_version, param, arg).then((res) => {
            if (res) {
                res.json().then(data => {
                    this.setState({
                        locations: data,
                        date: arg
                    })
                })
            } else {
                console.log('Nothing')
            }
        })
    }

    render() {
        return(
            <>
                <Header/>
                <Container fluid="sm md lg xl xxl">
                    <div>
                        <ScrollUpButton
                            StopPosition={0}
                            AnimationDuration={500}
                        />
                    </div>
                    <Row className="justify-content-md-center">
                        <Col sm={12} lg={6} md={6} xl={6} xs={12} xxl={6}>
                            <p/>
                            <YMaps>
                                {/*<Map width={640} height={500} defaultState={{center: [59.579754, 30.131000], zoom: 10}}>*/}
                                <Map style={{width:'100%', height:'500px'}} defaultState={{center: [59.579754, 30.131000], zoom: 12}}>
                                    <Clusterer options={{
                                        preset: 'islands#invertedRedClusterIcons',
                                        groupByCoordinates: false
                                    }}>
                                        {this.state.locations && this.state.locations.map((item, key) =>
                                            <Placemark key={key}
                                                       options={{iconColor: 'red'}}
                                                       defaultGeometry={[item.latitude, item.longitude]}
                                                       properties={{iconCaption: item.date.match(/[0-9]{2}:[0-9]{2}/)}}
                                            />
                                        )}
                                    </Clusterer>
                                </Map>
                            </YMaps>
                            <p/>
                            <Tabs id="tabs" className="mb-3" defaultActiveKey="Weather">
                                <Tab eventKey="Weather" title="Погода">
                                    <Weather date={this.state.date} />
                                </Tab>
                                <Tab eventKey="Top" title="ТОП 5">
                                    <Top/>
                                </Tab>
                                <Tab eventKey="Graph" title="График">
                                    <Graph/>
                                </Tab>
                            </Tabs>
                        </Col>
                        <Col sm={"auto"} lg={"auto"} md={"auto"} xl={"auto"} xs={"auto"} xxl={"auto"}>
                            <p/>
                            <Calendar onChange={this.clickHandler} />
                            <p/>
                            <Alert variant="success" className={"font-link"}>
                                Оставить сигнал о вони в Telegram
                            </Alert>
                            <img width={'350px'} src="qr_tgm.jpeg" alt="Telegram"/>
                            <p className="text-center">
                                <a className="link-primary" href={"https://t.me/EcoGatchina_bot"}>@EcoGatchina_bot</a>
                            </p>
                        </Col>
                    </Row>
                </Container>
                <p/>
                <hr/>
                <AboutProject/>
                <p/>
                <hr/>
                <AboutAPI/>
                <p/>
                <hr/>
                <Footer/>
            </>
        )
    }
}