import React, { Component } from 'react';

export default class Footer extends Component {
    render() {
        return (
            <div className='text-left p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                 v0.2 © 2022 Copyright:
                <a className='text-reset fw-bold' href='https://eco.gtn47.com/'>
                 eco.gtn47.com
                </a>
            </div>
        )
    }
}
