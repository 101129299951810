const windDirValues = {
    n: "Северный",
    nw: "Северо-Западный",
    ne: "Северо-Восточный",
    e: "Восточный",
    se: "Юго-Восточный",
    s: "Южный",
    sw: "Юго-Западный",
    w: "Восточный",
    c: "Штиль",
};
export default windDirValues
