import {Component} from "react";
import {Alert, Col, Container, Row} from "react-bootstrap";
import {Element} from "react-scroll";

export default class AboutProject extends Component {
    render() {
        return (
            <Element name="about_project">
                <Alert variant="secondary">
                    <Container fluid="sm md lg xl xxl">
                        <Row>
                            <Col className="font-link">
                                <h3>О проекте</h3>
                                <p/>
                                Проект создан с целью сбора данных о распространении зловоний в Гатчине и ближайших населённых пунктах в связи с возможным ухудшением экологической обстановки.
                                <p/>
                                Сбор данных производится посредством телеграмм-бота <a href={"https://t.me/EcoGatchina_bot"}>@EcoGatchina_bot</a>. Нажав кнопку «Воняет», жители сообщают боту свою геопозицию, где чувствуется запах. Сообщения автоматически обрабатываются и формируются в виде карты на этом сайте. С помощью календаря можно посмотреть историю геоотметок.
                                <p/>
                                Все данные являются открытыми и предоставляются as is (“как есть») по средствам публичного API интерфейса.
                                <p/>
                                Проект создан на добровольных началах, не является коммерческим и будет дорабатываться по мере необходимости.
                                <p/>
                            </Col>
                        </Row>
                    </Container>
                </Alert>
            </Element>
            )

    }
}