export default function ApiFetcher(endpoint, method, api_version, param, arg ) {
    const url = (typeof param == 'undefined') ?
        `https://eco-api.gtn47.com/${api_version}/${endpoint}` :
        `https://eco-api.gtn47.com/${api_version}/${endpoint}?${param}=${arg}`
    return fetch(url, {
        method: method,
    }).then((response) => {
        if (response.status === 200) {
            return response
        }
        else if (response.status === 404) {
            console.log('404 return')
        }
        else {
            console.log("¯\\_(ツ)_/¯")
        }
    }).catch(error => {
        alert(error);
    })
}