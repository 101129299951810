import { YMInitializer } from 'react-yandex-metrika';
import React  from 'react';

class YaMetrica extends React.Component {
  render() {
    return (
      <div>
        {/*SNIP*/}
          <YMInitializer accounts={[91364684]} options={{webvisor: true}} version="2"/>
        {/*SNIP*/}
      </div>
    );
  }
};

export default YaMetrica