import {Component} from "react";
import ApiFetcher from "../wrappers/ApiFetcher";
import {Col, Row} from "react-bootstrap";
import {ReactComponent as Wind} from "../weather_icon/ic_wind.svg";
import {ReactComponent as Temp} from "../weather_icon/ic_temp.svg";
import {ReactComponent as Humidity} from "../weather_icon/ic_humidity.svg";
import {ReactComponent as Pressure} from "../weather_icon/ic_pressure.svg";
import windDirValues from "./WeatherMap";

export default class Weather extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: "",
            feels_like: "",
            humidity: "",
            icon: "",
            id: "",
            pressureMm: "",
            temp: "",
            windDir: "",
            windGust: "",
            windSpeed: "",
            weatherHeader: "Сегодня: "
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        const endpoint = 'weather'
        const api_version = '0.2'
        ApiFetcher(endpoint, 'GET', api_version).then((res) => {
            if (res) {
                res.json().then(data => {
                    this.setState({
                        date: data[0].date,
                        feels_like: data[0].feels_like,
                        humidity: data[0].humidity,
                        icon: data[0].icon,
                        id: data[0].id,
                        pressureMm: data[0].pressureMm,
                        temp: data[0].temp,
                        windGust: data[0].windGust,
                        windSpeed: data[0].windSpeed,
                        windDir: windDirValues[data[0].windDir]
                    })
                })
            } else {
                console.log('Nothing')
            }
        })
    }

    componentDidUpdate(prevProps) {
        const endpoint = 'weather';
        const api_version = '0.2';
        const param = 'date';
        const arg = this.props.date;
        if (this.props.date !== prevProps.date) {
            ApiFetcher(endpoint, 'GET', api_version, param, arg).then((res) => {
                if (res) {
                    res.json().then(data => {
                        let sum_temp = 0
                        let sum_humidity = 0
                        let sum_pressureMm = 0
                        let winDir_arr = []
                        for (let i in data) {
                            sum_temp = data[i].temp + sum_temp
                            sum_humidity = data[i].humidity + sum_humidity
                            sum_pressureMm = data[i].pressureMm + sum_pressureMm
                            winDir_arr.push(data[i].windDir)
                        }
                        // Find max count for windDir array
                        let counts = {}
                        winDir_arr.forEach(function(x) { counts[x] = (counts[x]||0)+1;});
                        let max_key = Object.keys(counts).reduce(function(a, b){ return counts[a] > counts[b] ? a : b });
                        let avr_temp = (sum_temp/data.length).toFixed(1)
                        let avr_humidity = (sum_humidity/data.length).toFixed(1)
                        let avr_pressureMm = (sum_pressureMm/data.length).toFixed(1)
                        this.setState({
                            weatherHeader: "Среднесуточные показатели: ",
                            temp: avr_temp,
                            humidity: avr_humidity,
                            pressureMm: avr_pressureMm,
                            windDir: windDirValues[max_key]
                        })
                    })
                }
            })
        }
    }

    render() {
        return(
            <Row>
                <Col sm={"auto"} lg={"auto"} md={"auto"} xl={"auto"} xs={"auto"} xxl={"auto"}>
                    {this.state.weatherHeader}<Wind/> {this.state.windDir} <Temp/> {this.state.temp} C <Humidity/> {this.state.humidity}% <Pressure/> {this.state.pressureMm} Мм
                    <br/>
                    <a href="https://yandex.ru/pogoda"><img src="logo_ya_weather_black.png" width="100" alt="Яндекс Погода"/></a>
                    <br/>
                </Col>
            </Row>
        )
    }
}