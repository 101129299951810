import {Component} from "react";
import {Element} from "react-scroll";
import {Container, Alert, Row, Col, Accordion} from "react-bootstrap";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";

export default class AboutAPI extends Component {
    render() {
        return(
            <>
                <Element name="about_api">
                    <Alert variant={"light"}>
                        <Container fluid="sm md lg xl xxl">
                            <Row>
                                <Col>
                                    <h2>API</h2>
                                    Сервис обладает API интерфейсом, с помощью которого можно получать данные. Ответы приходят в формате <b><i>JSON</i></b>
                                    <p/>
                                    <Alert variant={"danger"}>
                                        <Alert.Heading>Актуальная версия API</Alert.Heading>
                                        Внимание, на данный момент актуальная версия API - <b>0.2</b>
                                    </Alert>
                                    <h4>Формат запроса</h4>
                                    Поддерживаются GET на адрес следующего формата <b><i>https://eco-api.gtn47.com/{'<версия_api>'}/{'<имя_ручки>'}</i></b>
                                    <br/>
                                    <p></p>
                                    <Accordion defaultActiveKey="0" alwaysOpen>
                                        <AccordionItem eventKey="0">
                                            <AccordionHeader>V0.2</AccordionHeader>
                                            <AccordionBody>
                                                <Accordion defaultActiveKey="0">
                                                <AccordionItem eventKey="0">
                                                    <AccordionHeader>/locations</AccordionHeader>
                                                    <AccordionBody>
                                                        Ручка возвращает все записи о сигналах запаха.
                                                        <br/>
                                                        <b><i>*date</i></b> - опциональный аргумент в формате <b><i>YYYY-MM-DD</i></b>; Позволяет получить записи за определенную дату.
                                                        <p/>
                                                        <h4>Формат ответа</h4>
                                                        Успешным считается <b><i>HTTP</i></b> ответ с кодом <b><i>200</i></b> и HTTP-заголовками:
                                                        <i>
                                                            <br/>Content-Type: application/json; charset=utf-8
                                                            <br/>X-Total-Count: {'<количество_возвращенных_элементов>'}
                                                        </i>
                                                        <p/>
                                                        <h4>Пример:</h4>
                                                        <p/>
                                                        <i>
                                                        [
                                                        {'{'}
                                                        "id": 2,
                                                        "longitude": 30.123861,
                                                        "latitude": 59.570873,
                                                        "timestamp": 1669021228,
                                                        "date": "2022-11-21 09:00:28"
                                                        }
                                                        ] </i>
                                                        где,<br/>
                                                        <b><i>id</i></b> - порядковый номер;<br/>
                                                        <b><i>longitude</i></b> - долгота;<br/>
                                                        <b><i>latitude</i></b> - широта;<br/>
                                                        <b><i>timestamp</i></b> - время сообщения в формате UNIX timestamp;<br/>
                                                        <b><i>date</i></b> - время сообщения, часовой пояс 'Europe/Moscow'<br/>
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey="1">
                                                    <AccordionHeader>/weather</AccordionHeader>
                                                    <AccordionBody>
                                                        Ручка возвращает данные о погоде на текущую дату. Данные предоставляются сервисом Яндекс Погода.
                                                        <br/>
                                                        <b><i>*date</i></b> - опциональный аргумент в формате <b><i>YYYY-MM-DD</i></b>; Позволяет получить список записей за определенную дату.
                                                        <p/>
                                                        <h4>Формат ответа</h4>
                                                        Успешным считается <b><i>HTTP</i></b> ответ с кодом <b><i>200</i></b>
                                                        <p/>
                                                        <h4>Пример:</h4>
                                                        <p/>
                                                        <i>
                                                        [
                                                        {'{'}
                                                            "id": 1902,
                                                            "temp": -7,
                                                            "feels_like": -11,
                                                            "icon": "bkn_d",
                                                            "windSpeed": 2,
                                                            "windDir": "s",
                                                            "windGust": 4,
                                                            "pressureMm": 741,
                                                            "humidity": 89,
                                                            "date": "2023-02-20 10:24:47"
                                                        }
                                                        ]
                                                        </i>
                                                        где,<br/>
                                                        <b><i>id</i></b> - порядковый номер;<br/>
                                                        <b><i>temp</i></b> - температура в градусах Цельсия;<br/>
                                                        <b><i>feels_like</i></b> - "ощущаестся как", в градусах Цельсия;<br/>
                                                        <b><i>icon</i></b> - строка, имя картинки от Яндекс Погоды;<br/>
                                                        <b><i>windSpeed</i></b> - Скорость ветра в м/с;<br/>
                                                        <b><i>windDir</i></b> - Направление ветра. Может иметь значения: n: "Северный",
                                                        nw: "Северо-Западный",
                                                        ne: "Северо-Восточный",
                                                        e: "Восточный",
                                                        se: "Юго-Восточный",
                                                        s: "Южный",
                                                        sw: "Юго-Западный",
                                                        w: "Восточный",
                                                        c: "Штиль";<br/>
                                                        <b><i>windGust</i></b> - Скорость порывов ветра в м/с;<br/>
                                                        <b><i>pressureMm</i></b> - Давление в мм. ртутного столба;<br/>
                                                        <b><i>humidity</i></b> - Влажность;<br/>
                                                        <b><i>date</i></b> - время сообщения, часовой пояс 'Europe/Moscow'<br/>
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey="2">
                                                    <AccordionHeader>/top</AccordionHeader>
                                                    <AccordionBody>
                                                        Возвращает ТОП N дней с наибольшим количеством отметок, в порядке убывания.
                                                        <br/>
                                                        По умолчанию <b><i>N = 10</i></b>
                                                        <b><i>*n</i></b> - количество возвращаемых записей;
                                                        <p/>
                                                        <h4>Формат ответа</h4>
                                                        Успешным считается <b><i>HTTP</i></b> ответ с кодом <b><i>200</i></b>
                                                        <p/>
                                                        <h4>Пример:</h4>
                                                        <p/>
                                                        <i>
                                                            [
                                                            {'{'}
                                                                "date": "2022-12-11",
                                                                "count": 341
                                                            },
                                                            ...
                                                            ]
                                                        </i>
                                                        где,<br/>
                                                        <b><i>date</i></b> - дата в формате <b><i>YYYY-MM-DD</i></b>;<br/>
                                                        <b><i>count</i></b> - количество записей в базе, с датой date;<br/>
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey="3">
                                                    <AccordionHeader>/daycounter</AccordionHeader>
                                                    <AccordionBody>
                                                        Возвращает 30 последних дней и количество отметок за эти дни.
                                                        <br/>
                                                        <p/>
                                                        <h4>Формат ответа</h4>
                                                        Успешным считается <b><i>HTTP</i></b> ответ с кодом <b><i>200</i></b>
                                                        <p/>
                                                        Формат ответа идентичен ответу ручки /top
                                                    </AccordionBody>
                                                </AccordionItem>
                                                </Accordion>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem eventKey="1">
                                            <AccordionHeader>V0.1</AccordionHeader>
                                            <AccordionBody>
                                                <h4>/locations</h4>
                                                Ручка возвращает все записи в формате <b><i>JSON</i></b>.
                                                <br/>
                                                <b><i>*date</i></b> - опциональный аргумент в формате <b><i>YYYY-MM-DD</i></b>; Позволяет получить записи за определенную дату.
                                                <p/>
                                                <h4>Формат ответа</h4>
                                                Успешным считается <b><i>HTTP</i></b> ответ с кодом <b><i>200</i></b> и HTTP-заголовками:
                                                <i>
                                                    <br/>Content-Type: application/json; charset=utf-8
                                                    <br/>X-Total-Count: {'<количество_возвращенных_элементов>'}
                                                </i>
                                                <p/>
                                                <h4>Пример:</h4>
                                                <p/>
                                                [
                                                {'{'}
                                                "id": 2,
                                                "longitude": 30.123861,
                                                "latitude": 59.570873,
                                                "timestamp": 1669021228,
                                                "date": "2022-11-21 09:00:28"
                                                }
                                                ]
                                                где,<br/>
                                                <b><i>id</i></b> - порядковый номер;<br/>
                                                <b><i>longitude</i></b> - долгота;<br/>
                                                <b><i>latitude</i></b> - широта;<br/>
                                                <b><i>timestamp</i></b> - время сообщения в формате UNIX timestamp;<br/>
                                                <b><i>date</i></b> - время сообщения, часовой пояс 'Europe/Moscow'
                                            </AccordionBody>
                                        </AccordionItem>
                                    </Accordion>
                                </Col>
                            </Row>
                        </Container>
                    </Alert>
                </Element>
            </>
        )
    }
}