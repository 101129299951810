import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import {Fragment} from "react";
import MainMap from "./components/MainMap";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route path="/" element={<MainMap />}/>
        </Routes>
      </Fragment>
    </BrowserRouter>
  );
}

export default App;
