export default function ApiPoster(endpoint, method, api_version, body ) {
    // const url = `http://localhost:80/${api_version}/${endpoint}`
    const url = `https://eco-api.gtn47.com/${api_version}/${endpoint}`
    return fetch(url, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: body
    }).then((response) => {
        if (response.status === 200) {
            return response
        }
        else if (response.status === 404) {
            console.log('404 return')
        }
        else {
            console.log("another error code")
        }
    }).catch(error => {
        console.log("¯\\_(ツ)_/¯");
    })
}