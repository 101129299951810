import { Component } from "react";
import { getFingerprint, getRawFingerprint } from "react-fingerprint";
import ApiPoster from "../wrappers/ApiPoster";

export default class FP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hash: "",
            audio_duration: "",
            audio_lenght: "",
            audio_numberOfChannels: "",
            audio_sampleRate: "",
            browser_app_codename: "",
            browser_app_name: "",
            browser_bars_menubar: "",
            browser_bars_personalbar: "",
            browser_bars_statusbar: "",
            browser_bars_toolbar: "",
            browser_canvas: "",
            browser_capabilities_contacts: "",
            browser_capabilities_cookies: "",
            browser_capabilities_pdfVieser: "",
            browser_doNotTrack: "",
            browser_language_current: "",
            browser_language_list: "",
            browser_mimeTypes: "",
            browser_plugins: "",
            browser_product_name: "",
            browser_product_sub: "",
            browser_userAgent: "",
            browser_vendor: "",
            device_color_contrast: "",
            device_color_depth: "",
            device_color_forcedColors: "",
            device_color_gamut: "",
            device_color_hdr: "",
            device_color_monochromeDepth: "",
            device_cpu_cores: "",
            device_cpu_name: "",
            device_memory: "",
            device_sizes_screen_height: "",
            device_sizes_screen_width: "",
            device_sizes_usable_height: "",
            device_sizes_usable_width: "",
            device_touchscreen: "",
            fonts: "",
            math_acos: "",
            math_acosh: "",
            math_acoshPf: "",
            math_asin: "",
            math_asinh: "",
            math_asinhPf: "",
            math_atan: "",
            math_atanh: "",
            math_atanhPf: "",
            math_cos: "",
            math_cosh: "",
            math_coshPf: "",
            math_exp: "",
            math_exp1: "",
            math_exp1Pf: "",
            math_log1p: "",
            math_log1Pf: "",
            math_powPI: "",
            math_sin: "",
            math_sinh: "",
            math_sinhPf: "",
            math_tan: "",
            math_tanh: "",
            math_tanhPf: "",
            reducedMotion: "",
            timezone: "",
            webgl_renderer: "",
            webgl_vendor: ""
          };
    }

    componentDidMount() {
        const endpoint = 'fp'
        const api_version = '0.2'
        getFingerprint().then((fp) => {
            this.setState({
                hash: fp
            })
            getRawFingerprint().then((res) => {
                let body = JSON.stringify({
                    "hash": fp,
                    "audio_duration": res.audio.duration,
                    "audio_lenght": res.audio.length,
                    "audio_numberOfChannels": res.audio.numberOfChannels,
                    "audio_sampleRate": res.audio.sampleRate,
                    "browser_app_codename": res.browser.app.codename,
                    "browser_app_name": res.browser.app.name,
                    "browser_bars_menubar": res.browser.bars.menubar,
                    "browser_bars_personalbar": res.browser.bars.personalbar,
                    "browser_bars_statusbar": res.browser.bars.statusbar,
                    "browser_bars_toolbar": res.browser.bars.toolbar,
                    "browser_canvas": res.browser.canvas,
                    "browser_capabilities_contacts": res.browser.capabilities.contacts,
                    "browser_capabilities_cookies": res.browser.capabilities.cookies,
                    "browser_capabilities_pdfViewer": res.browser.capabilities.pdfViewer,
                    "browser_doNotTrack": res.browser.doNotTrack,
                    "browser_language_current": res.browser.language.current,
                    "browser_language_list": res.browser.language.list,
                    "browser_mimeTypes": res.browser.mimeTypes,
                    "browser_plugins": res.browser.plugins,
                    "browser_product_name": res.browser.product.name,
                    "browser_product_sub": res.browser.product.sub,
                    "browser_userAgent": res.browser.userAgent,
                    "browser_vendor": res.browser.vendor,
                    "device_color_contrast": res.device.color.contrast,
                    "device_color_depth": res.device.color.depth,
                    "device_color_forcedColors": res.device.color.forcedColors,
                    "device_color_gamut": res.device.color.gamut,
                    "device_color_hdr": res.device.color.hdr,
                    "device_color_monochromeDepth": res.device.color.monochromeDepth,
                    "device_cpu_cores": res.device.cpu.cores,
                    "device_cpu_name": res.device.cpu.name,
                    "device_memory": res.device.memory,
                    "device_sizes_screen_height": res.device.sizes.screen.height,
                    "device_sizes_screen_width": res.device.sizes.screen.width,
                    "device_sizes_usable_height": res.device.sizes.usable.height,
                    "device_sizes_usable_width": res.device.sizes.usable.width,
                    "device_touchscreen": res.device.touchscreen,
                    "fonts": res.fonts,
                    "math_acos": res.math.acos,
                    "math_acosh": res.math.acosh,
                    "math_acoshPf": res.math.acoshPf,
                    "math_asin": res.math.asin,
                    "math_asinh": res.math.asinh,
                    "math_asinhPf": res.math.asinhPf,
                    "math_atan": res.math.atan,
                    "math_atanh": res.math.atanh,
                    "math_atanhPf": res.math.atanhPf,
                    "math_cos": res.math.cos,
                    "math_cosh": res.math.cosh,
                    "math_coshPf": res.math.coshPf,
                    "math_exp": res.math.exp,
                    "math_exp1": res.math.expm1,
                    "math_exp1Pf": res.math.expm1Pf,
                    "math_log1p": res.math.log1p,
                    "math_log1Pf": res.math.log1pPf,
                    "math_powPI": res.math.powPI,
                    "math_sin": res.math.sin,
                    "math_sinh": res.math.sinh,
                    "math_sinhPf": res.math.sinhPf,
                    "math_tan": res.math.tan,
                    "math_tanh": res.math.tanh,
                    "math_tanhPf": res.math.tanhPf,
                    "reducedMotion": res.reducedMotion,
                    "timezone": res.timezone,
                    "webgl_renderer": res.webgl.renderer,
                    "webgl_vendor": res.webgl.vendor
                })
                ApiPoster(endpoint, 'POST', api_version, body)
            })
        })
    }

    componentDidUpdate() {
        // const endpoint = 'fp'
        // const api_version = '0.2'
        // getRawFingerprint().then((res) => {
        //     let body = JSON.stringify({
        //         "hash": this.state.hash,
        //         "audio_duration": res.audio.duration,
        //         "audio_lenght": res.audio.length,
        //         "audio_numberOfChannels": res.audio.numberOfChannels,
        //         "audio_sampleRate": res.audio.sampleRate,
        //         "browser_app_codename": res.browser.app.codename,
        //         "browser_app_name": res.browser.app.name,
        //         "browser_bars_menubar": res.browser.bars.menubar,
        //         "browser_bars_personalbar": res.browser.bars.personalbar,
        //         "browser_bars_statusbar": res.browser.bars.statusbar,
        //         "browser_bars_toolbar": res.browser.bars.toolbar,
        //         "browser_canvas": res.browser.canvas,
        //         "browser_capabilities_contacts": res.browser.capabilities.contacts,
        //         "browser_capabilities_cookies": res.browser.capabilities.cookies,
        //         "browser_capabilities_pdfViewer": res.browser.capabilities.pdfViewer,
        //         "browser_doNotTrack": res.browser.doNotTrack,
        //         "browser_language_current": res.browser.language.current,
        //         "browser_language_list": res.browser.language.list,
        //         "browser_mimeTypes": res.browser.mimeTypes,
        //         "browser_plugins": res.browser.plugins,
        //         "browser_product_name": res.browser.product.name,
        //         "browser_product_sub": res.browser.product.sub,
        //         "browser_userAgent": res.browser.userAgent,
        //         "browser_vendor": res.browser.vendor,
        //         "device_color_contrast": res.device.color.contrast,
        //         "device_color_depth": res.device.color.depth,
        //         "device_color_forcedColors": res.device.color.forcedColors,
        //         "device_color_gamut": res.device.color.gamut,
        //         "device_color_hdr": res.device.color.hdr,
        //         "device_color_monochromeDepth": res.device.color.monochromeDepth,
        //         "device_cpu_cores": res.device.cpu.cores,
        //         "device_cpu_name": res.device.cpu.name,
        //         "device_memory": res.device.memory,
        //         "device_sizes_screen_height": res.device.sizes.screen.height,
        //         "device_sizes_screen_width": res.device.sizes.screen.width,
        //         "device_sizes_usable_height": res.device.sizes.usable.height,
        //         "device_sizes_usable_width": res.device.sizes.usable.width,
        //         "device_touchscreen": res.device.touchscreen,
        //         "fonts": res.fonts,
        //         "math_acos": res.math.acos,
        //         "math_acosh": res.math.acosh,
        //         "math_acoshPf": res.math.acoshPf,
        //         "math_asin": res.math.asin,
        //         "math_asinh": res.math.asinh,
        //         "math_asinhPf": res.math.asinhPf,
        //         "math_atan": res.math.atan,
        //         "math_atanh": res.math.atanh,
        //         "math_atanhPf": res.math.atanhPf,
        //         "math_cos": res.math.cos,
        //         "math_cosh": res.math.cosh,
        //         "math_coshPf": res.math.coshPf,
        //         "math_exp": res.math.exp,
        //         "math_exp1": res.math.expm1,
        //         "math_exp1Pf": res.math.expm1Pf,
        //         "math_log1p": res.math.log1p,
        //         "math_log1Pf": res.math.log1pPf,
        //         "math_powPI": res.math.powPI,
        //         "math_sin": res.math.sin,
        //         "math_sinh": res.math.sinh,
        //         "math_sinhPf": res.math.sinhPf,
        //         "math_tan": res.math.tan,
        //         "math_tanh": res.math.tanh,
        //         "math_tanhPf": res.math.tanhPf,
        //         "reducedMotion": res.reducedMotion,
        //         "timezone": res.timezone,
        //         "webgl_renderer": res.webgl.renderer,
        //         "webgl_vendor": res.webgl.vendor
        //     })
        //     ApiPoster(endpoint, 'POST', api_version, body)
        // })
    }
    render(){
        return(
            <div></div>
        )
    }
};
