import React, { Component } from 'react'
import {Navbar, Nav, Container, Row, Col} from 'react-bootstrap';
import YaMetrica from "./YaMetrica";
import {Link} from "react-scroll";
import FP from "./FP"

export default class Header extends Component {
    render() {
        return (
            <>
            <Navbar bg="light" expand="lg" variant="light">
                <Navbar.Brand href="/">ECO</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Container>
                        <Row>
                            <Col>
                                <Nav className="mr-auto">
                                    <Nav.Link href="/">Карта</Nav.Link>
                                    <Nav.Link>
                                        <Link activeClass="active"
                                              to="about_project"
                                              spy={true}
                                              smooth={true}
                                              duration={500}>О проекте</Link>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <Link activeClass="active" to="about_api">API</Link>
                                    </Nav.Link>
                                </Nav>
                            </Col>
                         </Row>
                    </Container>
                </Navbar.Collapse>
            </Navbar>
            <YaMetrica/>
            <FP/>
            </>
        )
    }
}
